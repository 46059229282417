import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IText } from '../../types';

interface ITextChooser {
    topics: Array<IText>;
    onSelect: (id?: number) => void;
}

export const TextChooser: FC<ITextChooser> = ({
  topics,
  onSelect,
}) => {
  const { t } = useTranslation();

  const handleChoose = (event: any, id?: number) => {
    if (!event.detail) {
      return event.preventDefault();
    }
    onSelect(id);
  };

  return (
    <div className="text-chooser">
      <div style={{ fontSize: 13, display: 'flex', alignItems: 'center' }}>
        <img src="/svg/text.png" alt="text" />
        <div>
          {t('Here you can select a text')}
          :
        </div>
      </div>
      <div className="text-chooser__inner">
        {topics.map(({ id, topic }, index) => (
          <button
            key={id}
            className="text-chooser__item"
            onClick={(e) => handleChoose(e, id)}
          >
            {index + 1}
            .
            {topic}
          </button>
        ))}
      </div>
    </div>
  );
};
