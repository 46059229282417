const generate = (offset: number, size: number) => {
  const alpha = Array.from(Array(size)).map((e, i) => i + offset);
  return alpha.map((x) => String.fromCharCode(x));
};

const specialSymbols = [
  '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
  '~', '`', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '[', ']', '{', '}',
  '<', '>', '_', '-', '+', '=', '.', ',', '/', '\\', ' ', ':', ';', '?', 'ё', 'Ë',
];

export const useAlphabet = () => {
  const enSize = 26;
  const ruSize = 33;
  const enUppercase = generate(65, enSize);
  const enLowercase = generate(97, enSize);

  const ruUppercase = generate(1040, ruSize);
  const ruLowercase = generate(1072, ruSize);

  return [
    ...enUppercase,
    ...enLowercase,
    ...ruUppercase,
    ...ruLowercase,
    ...specialSymbols,
  ];
};
