import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EnumTheme } from '../../types';

const initialState = EnumTheme.Light;

export const {
  reducer: ThemeReducer,
  actions: ThemeActions,
} = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<EnumTheme>) => action.payload,
  },
});
