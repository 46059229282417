import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Emoji } from '../Emoji';
import { ILink } from '../../types';

interface INavigation {
    links: Array<ILink>;
}

const CustomNavLink: FC<any> = ({
  to,
  disabled,
  children,
}) => {
  if (disabled) {
    return <div className="nav__link">{children}</div>;
  }

  return (
    <NavLink
      to={to}
      exact
      className="nav__link"
      activeClassName="nav__link--active"
    >
      {children}
    </NavLink>
  );
};

export const Navigation: FC<INavigation> = ({ links }) => (
  <nav className="nav">
    <ul className="nav__wrapper">
      {links.map(({
        link, title, emoji, disabled,
      }) => (
        <li key={link}>
          <CustomNavLink
            to={link}
            disabled={disabled}
            exact
            className="nav__link"
            activeClassName="nav__link--active"
          >
            {title}
                  &nbsp;
            <Emoji emoji={emoji} />
            {disabled && <div className="nav__soon">soon</div>}
          </CustomNavLink>
        </li>
      ))}
    </ul>
  </nav>
);
