const DOMAIN = 'Keyboard';
const TELEGRAM_DOMAIN = 'https://api.telegram.org';
const TOKEN = '1808332335:AAGuUqrKDSC47jbd4ry-tLMwHzAfJrKLUys';
const CHAT_ID = 506899669;

export const useLogger = (options = {}) => {
  const { token, chatId } = options as any;

  const log = async (...args: Array<any>) => {
    const userAgent = window.navigator ? window.navigator.userAgent : '';

    const str = [`[${DOMAIN}]:`, ...args, '%0A%0A', `%0A${userAgent}`].reduce((acc, val) => {
      if (typeof val === 'object') {
        return `${acc} ${JSON.stringify(val)}`;
      }
      return `${acc} ${val}`;
    }, '');
    send(str);
  };

  const send = async (str: string) => {
    const link = `${TELEGRAM_DOMAIN}/bot${token || TOKEN}/sendMessage?chat_id=-${chatId || CHAT_ID}&text=${str}`;
    await fetch(link);
  };

  return {
    log,
  };
};
