import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import meanBy from 'lodash/meanBy';
import sumBy from 'lodash/sumBy';
import maxBy from 'lodash/maxBy';
import { formatDuration, intervalToDuration } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { IRootState } from '../../store/slice/state';
import { IMetaSpeed } from '../../types';
import { Characters } from '../Characters';
import { TextActions } from '../../store/slice/text';
import { Emoji } from '../Emoji';
import i18n from '../../i18n';

const prepareString = (parts: Array<string>, duration: Duration) => {
  if (duration.months) {
    return parts.slice(0, 4).join(' ');
  } if (duration.days) {
    return parts.slice(0, 4).join(' ');
  } if (duration.hours) {
    return parts.slice(0, 4).join(' ');
  } if (duration.minutes) {
    return parts.slice(0, 4).join(' ');
  }
  return parts.join(' ');
};
const formatTime = (ms: number) => {
  const duration: Duration = intervalToDuration({ start: 0, end: ms });

  const formattedString = formatDuration(duration, {
    format: ['months', 'days', 'hours', 'minutes', 'seconds'],
  });

  const parts = formattedString.split(' ').map((part) => part.trim());

  return prepareString(parts, duration)
    .replace(/ day(s)?/g, i18n.t('d'))
    .replace(/ hour(s)?/g, i18n.t('h'))
    .replace(/ minute(s)?/g, i18n.t('m'))
    .replace(/ second(s)?/g, i18n.t('s'));
};

export const Stats: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isCollapsed = useSelector(((state: IRootState) => state.text.meta.isCollapsed));
  const sessions = useSelector(((state: IRootState) => state.text.meta.sessions));

  const lastSession = sessions[sessions.length - 1] ?? {};
  const sessionTime = lastSession.time ?? 0;
  const { completedTexts } = lastSession;
  const { words } = lastSession;
  const { symbols } = lastSession;
  const { mistakes } = lastSession;
  const totalCharacters = symbols + mistakes;
  const rate = symbols ? (symbols / totalCharacters) * 100 : 100;
  const speed = lastSession.speed ?? {} as IMetaSpeed;

  const totalTime = sumBy(sessions, (session) => session.time ?? 0);
  const totalWords = sumBy(sessions, (session) => session.words ?? 0);
  const totalCompletedTexts = sumBy(sessions, (session) => session.completedTexts ?? 0);
  const averageTopSpeed = Number(meanBy(sessions, (session) => session.speed?.top ?? 0).toFixed(0));
  const totalTopSpeed = maxBy(sessions, (session) => session.speed?.top ?? 0)?.speed?.top ?? 0;

  const symbols2 = sumBy(sessions, (session) => session.symbols ?? 0);
  const mistakes2 = sumBy(sessions, (session) => session.mistakes ?? 0);
  const totalCharacters2 = symbols2 + mistakes2;
  const totalRate = symbols2 ? (symbols2 / totalCharacters2) * 100 : 100;

  const handleCollapse = (event: any) => {
    if (!event.detail) {
      return event.preventDefault();
    }
    dispatch(TextActions.setIsCollapsed(!isCollapsed));
  };

  return (
    <div className="stats">
      <div className={`stats__wrapper ${isCollapsed ? 'stats__wrapper--collapsed' : ''}`}>
        <div className="stats__header">
          {t('Session statistics')}
          :
        </div>
        <div>
          <Emoji emoji="handwrite" size={15} />
&nbsp;
          {t('Typed words')}
          :&nbsp;
          <Characters data={words} />
        </div>
        <div>
          <Emoji emoji="flag" size={15} />
&nbsp;
          {t('Completed texts')}
          :&nbsp;
          <Characters data={completedTexts} />
        </div>
        <div>
          <Emoji emoji="rocket" size={15} />
&nbsp;
          {t('Speed')}
          :&nbsp;
          <Characters data={speed.current} />
&nbsp;
          {t('s/min')}
        </div>
        <div>
          <Emoji emoji="high-voltage" size={15} />
&nbsp;
          {t('Top speed')}
          :&nbsp;
          <Characters data={speed.top} />
&nbsp;
          {t('s/min')}
        </div>
        <div>
          <Emoji emoji="bullseye" size={15} />
&nbsp;
          {t('Rate')}
          :&nbsp;
          <Characters data={rate.toFixed(2)} />
&nbsp;%
        </div>
        <div>
          <Emoji emoji="sand-time" size={15} />
&nbsp;
          {t('Session time')}
          :&nbsp;
          <Characters data={formatTime(sessionTime)} />
        </div>
        <br />
        <div className="stats__header">
          {t('General statistics')}
          :
        </div>
        <div>
          ️
          <Emoji emoji="handwrite" size={15} />
&nbsp;
          {t('Typed words')}
          :&nbsp;
          <Characters data={totalWords} />
        </div>
        <div>
          <Emoji emoji="flag" size={15} />
&nbsp;
          {t('Completed texts')}
          :&nbsp;
          <Characters data={totalCompletedTexts} />
        </div>
        <div>
          <Emoji emoji="rocket" size={15} />
&nbsp;
          {t('Avg speed')}
          :&nbsp;
          <Characters data={averageTopSpeed} />
&nbsp;
          {t('s/min')}
        </div>
        <div>
          <Emoji emoji="high-voltage" size={15} />
&nbsp;
          {t('Top speed')}
          :&nbsp;
          <Characters data={totalTopSpeed} />
&nbsp;
          {t('s/min')}
        </div>
        <div>
          <Emoji emoji="bullseye" size={15} />
&nbsp;
          {t('Avg rate')}
          :&nbsp;
          <Characters data={totalRate.toFixed(2)} />
&nbsp;%
        </div>
        <div>
          <Emoji emoji="sand-time" size={15} />
&nbsp;
          {t('Spent time')}
          :&nbsp;
          <Characters data={formatTime(totalTime)} />
        </div>
        <button onClick={handleCollapse}>
          <img
            src="/svg/arrow.svg"
            alt="text"
            className={`${!isCollapsed ? 'img--rotated' : ''}`}
          />
        </button>
      </div>
    </div>
  );
};
