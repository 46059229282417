import React, { FC } from 'react';

interface ICharacters {
    data: any;
}

const availableCharacters = new Set(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']);

export const Characters: FC<ICharacters> = ({
  data,
}) => {
  if (data === null || data === undefined) {
    return null;
  }

  return (
    <>
      {data?.toString()?.split('')?.map((character: string) => {
        if (availableCharacters.has(character)) {
          return <img src={`/svg/${character}.svg`} style={{ height: 9, width: 9, marginRight: -1 }} />;
        }
        return character;
      })}
    </>
  );
};
