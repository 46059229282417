import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Language } from '../../types';

const initialState = Language.English;

export const {
  reducer: LanguageReducer,
  actions: LanguageActions,
} = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<Language>) => action.payload,
  },
});
