import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IMeta, IMetaSession, IStatistics, IText, ITexts, TextStatus,
} from '../../types';

const initialState: ITexts = {
  texts: [],
  meta: {
    sessions: [],
    isCollapsed: true,
  },
  statistics: {},
};

const getLastSession = (state: ITexts) => {
  const { sessions } = state.meta;
  return sessions[sessions.length - 1];
};

const setLastSession = (state: ITexts, session: IMetaSession) => {
  const { sessions } = state.meta;
  if (getLastSession(state)) {
    sessions[sessions.length - 1] = session;
  }
};

export const {
  reducer: TextReducer,
  actions: TextActions,
} = createSlice({
  name: 'text',
  initialState,
  reducers: {
    setTexts: (state, action: PayloadAction<Array<IText>>) => {
      state.texts = action.payload;
    },
    setMeta: (state, action: PayloadAction<IMeta>) => {
      state.meta = action.payload;
    },
    setStatistics: (state, action: PayloadAction<IStatistics>) => {
      state.statistics = action.payload;
    },
    setIsCollapsed: (state, action: PayloadAction<boolean>) => {
      state.meta.isCollapsed = action.payload;
    },
    increaseMistakeCounter: (state) => {
      const lastSession = getLastSession(state);
      lastSession.mistakes += 1;
      setLastSession(state, lastSession);
    },
    increaseSymbolsCounter: (state) => {
      const lastSession = getLastSession(state);
      lastSession.symbols += 1;
      setLastSession(state, lastSession);
    },
    increaseWordsCounter: (state) => {
      const lastSession = getLastSession(state);
      lastSession.words += 1;
      setLastSession(state, lastSession);
    },
    increaseCompletedTextsCounter: (state) => {
      const lastSession = getLastSession(state);
      lastSession.completedTexts += 1;
      setLastSession(state, lastSession);
    },
    updateSpeed: (state, action: PayloadAction<number>) => {
      const lastSession = getLastSession(state);
      const maxSpeed = Math.max(action.payload, lastSession.speed.top);
      lastSession.speed = {
        current: action.payload,
        top: maxSpeed,
      };
      setLastSession(state, lastSession);
    },
    startSession: (state) => {
      const session: IMetaSession = {
        start: Date.now(),
        end: Date.now(),
        time: 0,
        speed: {
          top: 0,
          current: 0,
        },
        words: 0,
        mistakes: 0,
        symbols: 0,
        completedTexts: 0,
      };
      state.meta.sessions.push(session);
    },
    updateSessionTime: (state) => {
      const lastSession = getLastSession(state);
      lastSession.start = lastSession.start || Date.now();
      lastSession.end = Date.now();
      lastSession.time = lastSession.end - lastSession.start;
      setLastSession(state, lastSession);
    },
    increaseStatisticsByStatus: (state, action: PayloadAction<{ status: TextStatus }>) => {
      const { status } = action.payload;
      const statistics = state.statistics || {};

      const date = new Date();
      const day = `${date.getDate()} ${date.toLocaleString('en-US', { month: 'short' })}`;

      const dayStatistics = statistics[day] || [0, 0];

      if (status === TextStatus.AlreadyKnow) {
        dayStatistics[0]++;
      } else if (status === TextStatus.ToLearn) {
        dayStatistics[1]++;
      }

      state.statistics = {
        ...statistics,
        [day]: dayStatistics,
      };
    },
  },
});
