import { TextActions } from '../slice/text';
import { IMeta, IStatistics, IText } from '../../types';

const fetchTexts = () => async (dispatch: Function) => {
  try {
    const texts: Array<IText> = await fetch('/texts.json').then((r) => r.json());
    const normalizedTexts: Array<IText> = texts.map(({ text, topic }, index) => ({
      id: index, topic, text, symbols: [],
    }));
    dispatch(TextActions.setTexts(normalizedTexts));
  } catch (error) {
    console.error('restoreTexts', error);
  }
};

const saveMeta = (payload: IMeta) => async () => {
  try {
    localStorage.setItem('texts.meta', JSON.stringify(payload));
  } catch (error) {
    console.error('saveMeta', error);
  }
};

const restoreMeta = () => async (dispatch: Function) => {
  try {
    const meta = JSON.parse(String(localStorage.getItem('texts.meta')));
    if (meta) {
      dispatch(TextActions.setMeta(meta));
    }
  } catch (error) {
    console.error('restoreMeta', error);
  }
};

const saveStatistics = (payload: IStatistics) => async () => {
  try {
    localStorage.setItem('texts.statistics', JSON.stringify(payload));
  } catch (error) {
    console.error('saveStatistics', error);
  }
};

const restoreStatistics = () => async (dispatch: Function) => {
  try {
    const statistics = JSON.parse(String(localStorage.getItem('texts.statistics')));
    // dispatch(TextActions.setStatistics(statistics));
  } catch (error) {
    console.error('restoreStatistics', error);
  }
};

export const TextEffects = {
  fetchTexts,
  saveMeta,
  restoreMeta,
  saveStatistics,
  restoreStatistics,
};
