import { useEffect } from 'react';

export const useTimer = (
  callback: () => void,
  ms: number = 1000,
) => {
  useEffect(() => {
    const timer = setInterval(callback, ms);

    return () => {
      clearTimeout(timer);
    };
  }, []);
};
