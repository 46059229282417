import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { IRootState } from '../store/slice/state';
import i18n from '../i18n';
import { LanguageEffects } from '../store/effects';

export const useLanguage = () => {
  const dispatch = useDispatch();
  const language = useSelector((state: IRootState) => state.language);

  useEffect(() => {
    dispatch(LanguageEffects.restoreLanguage());
  }, []);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
};
