import sampleSize from 'lodash/sampleSize';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTimer } from './timer';
import { TextActions } from '../store/slice/text';
import { IRootState } from '../store/slice/state';
import { TextEffects } from '../store/effects';
import { ISymbol, IText, SymbolStatus } from '../types';

const prepareSymbols = (string: string) => string.split('')
  .map((character, index) => ({
    character,
    status: index === 0 ? SymbolStatus.Target : SymbolStatus.Untouched,
  }));

export const useText = () => {
  const dispatch = useDispatch();

  const [topics, setTopics] = useState<Array<IText>>([]);
  const [, setFrameUpdate] = useState(0);
  const symbols = useRef<Array<ISymbol>>([]);
  const pointer = useRef(0);
  const typingCounter = useRef<Array<number>>([]);

  const {
    texts,
    statistics,
  } = useSelector(((state: IRootState) => state.text));

  const [isTextCompleted, setIsTextCompleted] = useState(false);
  const sampleText = useRef<IText>();
  const textsRef = useRef<Array<IText>>();
  textsRef.current = texts;

  const nextText = (id?: number) => {
    setIsTextCompleted(false);
    if (id === undefined) {
      sampleText.current = sampleSize(textsRef.current, 1)[0] || {} as IText;
    } else {
      sampleText.current = textsRef.current?.[id] || {} as IText;
    }
    const rawText = sampleText.current?.text ?? '';
    symbols.current = prepareSymbols(rawText);
    pointer.current = 0;
  };

  useEffect(() => {
    const texts = sampleSize(textsRef.current, 100);
    setTopics(texts);
  }, [texts]);

  useEffect(() => {
    if (texts.length === 0) {
      dispatch(TextEffects.fetchTexts());
    } else if (!statistics) {
      dispatch(TextEffects.restoreStatistics());
    }
    if (texts && !symbols.current.length) {
      nextText();
    }
  }, [texts, statistics]);

  useTimer(() => {
    const COLLECT_S = 10;
    const NORMALIZE_TO_S = 60;
    const now = Date.now();

    const minTs = typingCounter.current[0] || 0;
    const minS = minTs ? (now - minTs) / 1000 : COLLECT_S;
    const minCollectSeconds = Math.min(COLLECT_S, minS);
    const normalizeMultiplier = NORMALIZE_TO_S / minCollectSeconds;
    const minTsThreshold = now - COLLECT_S * 1000;
    const symbolsForLastNCollectSeconds = typingCounter.current.filter((ts) => ts > minTsThreshold);
    const speed = symbolsForLastNCollectSeconds.length * normalizeMultiplier;

    typingCounter.current = symbolsForLastNCollectSeconds;

    dispatch(TextActions.updateSpeed(Number(speed.toFixed(0))));
  }, 1000);

  const onType = (character: string) => {
    const textLength = sampleText.current?.text?.length;
    if (!textLength) return;

    const MAX_SKIPPED = 5;
    const end = pointer.current + MAX_SKIPPED;
    const availableCharacters = symbols.current.slice(pointer.current, end).map((a) => a.character);
    const index = availableCharacters.findIndex((c) => c === character);

    const isWordCompleted = character === ' ';
    if (isWordCompleted) {
      dispatch(TextActions.increaseWordsCounter());
    }

    // todo: target typing rate
    const isTextCompleted = (pointer.current + 1) === textLength;
    if (isTextCompleted) {
      dispatch(TextActions.increaseCompletedTextsCounter());
    }

    if (index !== -1) {
      const offset = index + 1;
      if (offset === 1) {
        symbols.current[pointer.current].status = SymbolStatus.Typed;
        dispatch(TextActions.increaseSymbolsCounter());
        typingCounter.current.push(Date.now());
        if (isTextCompleted) {
          setIsTextCompleted(true);
          // nextText();
        } else {
          pointer.current += offset;
          symbols.current[pointer.current].status = SymbolStatus.Target;
        }
      } else {
        for (let i = 0; i < offset; i++) {
          if (i === offset - 1) {
            symbols.current[pointer.current].status = SymbolStatus.Typed;
            dispatch(TextActions.increaseSymbolsCounter());
            typingCounter.current.push(Date.now());
            if (isTextCompleted) {
              setIsTextCompleted(true);
              // nextText();
            } else {
              pointer.current += 1;
              symbols.current[pointer.current].status = SymbolStatus.Target;
            }
          } else {
            symbols.current[pointer.current].status = SymbolStatus.Skipped;
            dispatch(TextActions.increaseMistakeCounter());
            pointer.current += 1;
          }
        }
      }
    } else if (isTextCompleted) {
      setIsTextCompleted(true);
      // nextText();
    } else {
      // dispatch(TextActions.increaseMistakeCounter());
      symbols.current[pointer.current].status = SymbolStatus.Mistake;
    }
    setFrameUpdate((prev) => ++prev);
  };

  return {
    text: {
      id: sampleText.current?.id,
      text: sampleText.current?.text ?? '',
      topic: sampleText.current?.topic ?? '',
      symbols: symbols.current,
    },
    topics,
    onType,
    nextText,
    isTextCompleted,
    isTextLoaded: true,
  };
};
