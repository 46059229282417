import React, { FC, useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useTimer } from '../../use/timer';
import { ISymbol, IText, SymbolStatus } from '../../types';
import ConfettiExplosion from './ConfettiExplosion';

interface ICard {
  text?: IText;
  next?: () => void;
  style?: React.CSSProperties;
  isTextCompleted?: boolean;
}

export const TextCard: FC<ICard> = ({
  text,
  next,
  style,
  isTextCompleted,
}) => {
  const { t } = useTranslation();
  const timeRef = useRef(0);
  const statusRef = useRef<SymbolStatus>(SymbolStatus.Untouched);

  useTimer(() => {
    if (statusRef.current && statusRef.current !== SymbolStatus.Target) {
      timeRef.current += 1000;
    }
  }, 1000);

  useEffect(() => {
    timeRef.current = 0;
  }, [text?.id]);

  if (!text) return null;

  const { symbols } = text;
  statusRef.current = symbols[0]?.status;

  let word: Array<ISymbol> = [];
  const words = symbols.reduce<Array<Array<ISymbol>>>((acc, symbol) => {
    word.push(symbol);

    if (symbol.character === ' ') {
      acc.push(word);
      word = [];
    }

    return acc;
  }, []);
  words.push(word);

  return (
    <div className="card">
      <div className="card__inner" style={style}>
        {isTextCompleted && (
          <div className="card__finish">
            <ConfettiExplosion />
            <button onClick={() => next?.()}>
              🚀
              {t('Next')}
              {' '}
              🚀
            </button>
          </div>
        )}
        <div className={`card__wrapper ${isTextCompleted ? 'card__wrapper--finished' : ''}`}>
          {/* <div className="card__border card__border--right" style={side === 'right' ? style : {}} /> */}
          {/* <div className="card__border card__border--left" style={side === 'left' ? style : {}} /> */}
          <span className="card__id">{text?.topic}</span>
          <span className="card__time">{format(timeRef.current, 'mm:ss')}</span>
          <div className="card__text">
            {words.map((word, wIndex) => (
              <div key={`w-${wIndex}`} className="card__row">
                {word.map(({ character, status }, sIndex) => {
                  const classes = ['card__symbol'];
                  if (status === SymbolStatus.Typed) {
                    classes.push('card__symbol--typed');
                  } else if (status === SymbolStatus.Mistake) {
                    classes.push('card__symbol--mistake');
                  } else if (status === SymbolStatus.Target) {
                    classes.push('card__symbol--target');
                  } else if (status === SymbolStatus.Skipped) {
                    classes.push('card__symbol--skipped');
                  }
                  if (character === ' ') {
                    return <span key={`s-${sIndex}`} className={classes.join(' ')}>&nbsp;</span>;
                  }
                  return <span key={`s-${sIndex}`} className={classes.join(' ')}>{character}</span>;
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
