import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { Stats } from '../Stats';
import { TextCard } from '../TextCard';
import { TextChooser } from '../TextChooser';
import { useText } from '../../use/text';
import { useColor } from '../../use/colors';
import { useAlphabet } from '../../use/alphabet';
import { useKeyPress } from '../../use/keyPress';

interface ITextSection {
}

const isMobile = window.navigator.maxTouchPoints > 1;

export const TextSection: FC<ITextSection> = () => {
  const alphabet = useAlphabet();
  const [isRefresh, setIsRefresh] = useState(false);
  const {
    topics, text, isTextCompleted, onType, nextText,
  } = useText();

  const { hex } = useColor();

  useKeyPress(alphabet, (_, code) => {
    onType(code);
  }, false);

  const style = useMemo(() => ({
    borderColor: hex(),
  }), [text]);

  const handleRefresh = (event: any) => {
    if (!event.detail) {
      return event.preventDefault();
    }
    setIsRefresh(true);
  };

  useEffect(() => {
    if (isRefresh) {
      setTimeout(() => {
        nextText();
        setIsRefresh(false);
      }, 1000);
    }
  }, [isRefresh]);

  return (
    <section className="word-section">
      <Stats />

      <div className="word-section__inner">
        <div className="word-section__wrapper">
          <button
            className={`word-section__restart ${isRefresh ? 'word-section__restart--clicked' : ''}`}
            onClick={handleRefresh}
          >
            <img src="/svg/refresh.svg" alt="restart" />
          </button>
          {isMobile ? (
            <div className="word-section__info">
              <div>Please use your computer for training. 💪</div>
              <div>The site is temporarily not adapted for phones. 🚁</div>
            </div>
          ) : (
            <TextCard
              text={text}
              next={nextText}
              style={style}
              isTextCompleted={isTextCompleted}
            />
          )}
        </div>
      </div>
      <TextChooser
        topics={topics}
        onSelect={nextText}
      />
    </section>
  );
};
