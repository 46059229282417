import { combineReducers } from '@reduxjs/toolkit';
import { IRootState } from './state';
import { ThemeReducer } from './theme';
import { TextReducer } from './text';
import { LanguageReducer } from './language';

export const rootReducer = combineReducers<IRootState>({
  text: TextReducer,
  theme: ThemeReducer,
  language: LanguageReducer,
});
