import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { IRootState } from '../store/slice/state';
import { EnumTheme } from '../types';
import { ThemeEffects } from '../store/effects';

export const useTheme = () => {
  const dispatch = useDispatch();
  const appTheme = useSelector((state: IRootState) => state.theme);
  const theme = appTheme === EnumTheme.Dark ? 'container--dark' : 'container--light';

  useEffect(() => {
    dispatch(ThemeEffects.restoreTheme());
  }, [dispatch]);

  return {
    theme,
  };
};
