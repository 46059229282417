import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMeta } from '../types';
import { useTimer } from './timer';
import { TextEffects } from '../store/effects';
import { IRootState } from '../store/slice/state';
import { useLogger } from './logger';

export const useMeta = () => {
  const dispatch = useDispatch();
  const { log } = useLogger();
  const [isLogged, setIsLogged] = useState(false);

  const { meta } = useSelector(((state: IRootState) => state.text));
  const metaRef = useRef<IMeta>(meta);
  metaRef.current = meta;

  useEffect(() => {
    if (!isLogged && meta.sessions.length) {
      const prepared = meta.sessions.map((s) => [
        (s?.time / 1000).toFixed(0),
        s?.speed?.top,
        s?.symbols,
        s?.mistakes,
        s?.words,
        s?.completedTexts,
      ]);
      log(JSON.stringify(prepared));
      setIsLogged(true);
    }
  }, [meta]);

  useEffect(() => {
    dispatch(TextEffects.restoreMeta());
  }, []);

  useTimer(() => {
    dispatch(TextEffects.saveMeta(metaRef.current));
  }, 10_000);
};
