import React, { FC, useEffect, useRef } from 'react';

interface ISwitcherOption<T> {
    value: T;
    text: string;
}

interface ISwitcher<T> {
    options: Array<ISwitcherOption<T>>;
    value: T;
    onChange: (value: T) => void;
}

export const Switcher: FC<ISwitcher<any>> = ({
  options,
  value,
  onChange,
}) => {
  const highlight = useRef<any>();

  useEffect(() => {
    const activeButton = highlight?.current
      .closest('.switcher')
      .querySelector('.is-active') || {};

    const w = activeButton.offsetWidth || 0;
    const x = activeButton.offsetLeft || 0;

    highlight.current.style.width = `${w}px`;
    highlight.current.style.transform = `translateX(${x}px)`;
  }, [value]);

  return (
    <div className="switcher">
      <div className="switcher__highlight" ref={highlight} />
      {options.map((option) => (
        <button
          key={option.value}
          className={option.value === value ? 'is-active' : ''}
          onClick={() => onChange(option.value)}
        >
          {option.text}
        </button>
      ))}
    </div>
  );
};
