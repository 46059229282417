import React, { FC } from 'react';

interface IEmoji {
    emoji: string;
    size?: number;
}

export const Emoji: FC<IEmoji> = ({ emoji, size = 20 }) => (
  <img
    style={{
      width: size,
      height: size,
    }}
    src={`/emoji/${emoji}.png`}
    className="emoji"
    alt={emoji}
  />
);
