import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTimer } from './timer';
import { TextActions } from '../store/slice/text';

export const useSession = () => {
  const dispatch = useDispatch();

  useTimer(() => {
    dispatch(TextActions.updateSessionTime());
  }, 1000);

  useEffect(() => {
    dispatch(TextActions.startSession());
  }, []);
};
