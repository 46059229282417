import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Header } from '../components/Header';
import { ILink } from '../types';
import { useMeta } from '../use/meta';
import { useSession } from '../use/session';
import { TextSection } from '../components/TextSection';
import { Statistics } from '../components/Statistics';
import { useLanguage } from '../use/language';
import { useTheme } from '../use/theme';

export const Main: FC = () => {
  const { t } = useTranslation();

  const links: Array<ILink> = [
    {
      link: '/', title: t('Texts'), emoji: 'yarn', component: () => <TextSection />,
    },
    {
      link: '/stats', title: t('Stats'), emoji: 'dog', component: () => <Statistics />, disabled: true,
    },
  ];

  useMeta();
  useSession();
  useLanguage();

  const { theme } = useTheme();

  return (
    <div className={`container ${theme}`}>
      <Header links={links} />
      <main>
        {links.map(({ link, component }) => (
          <Route
            key={link}
            path={link}
            exact
            render={component}
          />
        ))}
      </main>
    </div>
  );
};
