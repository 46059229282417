import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { Language } from '../types';

console.log('init');

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: Language.English,
    saveMissing: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
