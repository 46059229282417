import { Language } from '../../types';
import { LanguageActions } from '../slice/language';

const restoreLanguage = () => async (dispatch: Function) => {
  try {
    const language = JSON.parse(String(localStorage.getItem('language')));
    if (language) {
      dispatch(LanguageActions.setLanguage(language));
    }
  } catch (error) {
    console.error('restoreLanguage', error);
  }
};

const saveLanguage = (payload: Language) => async (dispatch: Function) => {
  try {
    localStorage.setItem('language', JSON.stringify(payload));
    dispatch(LanguageActions.setLanguage(payload));
  } catch (error) {
    console.error('saveLanguage', error);
  }
};

export const LanguageEffects = {
  restoreLanguage,
  saveLanguage,
};
