export enum TextStatus {
  AlreadyKnow,
  ToLearn,
}

export enum SymbolStatus {
  Untouched,
  Typed,
  Mistake,
  Target,
  Skipped,
}

export interface ISymbol {
  character: string;
  status: SymbolStatus;
}

export interface IText {
  id?: number;
  topic: string;
  text: string;
  symbols: Array<ISymbol>;
}

export interface IMetaText {
  status: TextStatus;
}

export interface IMetaSession {
  start: number;
  end: number;
  time: number;
  speed: IMetaSpeed;
  words: number;
  completedTexts: number;
  mistakes: number;
  symbols: number;
}

export interface IMetaSpeed {
  current: number;
  top: number;
}

export interface IMeta {
  // words: number;
  // completedTexts: number;
  sessions: Array<IMetaSession>;
  isCollapsed: boolean;
  // speed: IMetaSpeed;
}

export type IStatistics = Record<string, [number, number]>;

export interface ITexts {
  texts: Array<IText>;
  meta: IMeta;
  statistics: IStatistics;
}
