import React, { FC } from 'react';
import { useStatistics } from '../../use/statistics';

export const Graph: FC = () => {
  const { barData } = useStatistics();

  return (
    <div className="graph">
      {barData.length ? barData.map(({
        date, know, learn, knowPercent, learnPercent,
      }) => (
        <div className="graph__vertical">
          <div className="graph__bar">
            <div className="graph__bar-filler graph__bar-filler--blue" style={{ height: `${knowPercent}%` }}>
              <span>{know}</span>
            </div>
            <div className="graph__bar-filler graph__bar-filler--yellow" style={{ height: `${learnPercent}%` }}>
              <span>{learn}</span>
            </div>
          </div>
          <div className="graph__bar-date">{date}</div>
        </div>
      )) : <span className="graph--empty">Statistics is empty. Please type at least 1 word. 🙃</span>}
    </div>
  );
};
